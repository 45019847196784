import {
    BP1658CJ,
    BP5768D,
    BRIGHTNESS_CCT_PWMS,
    BUTTON_TOGGLES_ON_OFF,
    GPIO_25, GPIO_27, I2C, MCU_UART_PROTOCOL, MODULE_GEN2B,
    NO,
    NOVOLINK_BUTTON,
    PWMS, SETUP_MODE_ONLY,
    WHITE_ONLY,
    YES
} from "./WizardPages";
import { getPossibleGpios } from "./Gpios";

let hardCoded = (me, defaultValue) => {
    me.setReadableValue(defaultValue);
}

let whenWhiteOnlySetNA = (me, pages) => {
    me.editable = true;
    let answer = getQuestionAnswer(pages[1], 0)
    if (answer && answer.key === WHITE_ONLY) {
        me.setReadableValue('n/a');
    }
}

let whenNotPwmSetNA = (me, pages) => {
    me.editable = true;
    let answer = getQuestionAnswer(pages[0], 0)
    if (answer && answer.key !== PWMS) {
        me.setReadableValue('n/a');
    }
}

let whenNotPwmSet0 = (me, pages) => {
    me.editable = true;
    let answer = getQuestionAnswer(pages[0], 0)
    if (answer && answer.key !== PWMS) {
        me.setReadableValue('0');
    }
}

let when3ChannelsSetNa = (me, pages) => {
    me.editable = true;
    let numChannelsAnswer = getQuestionAnswer(pages[11], 0)
    if (numChannelsAnswer && numChannelsAnswer.key !== '3') {
        me.setReadableValue('n/a');
    }
}

let whenNot3ChannelsSet0 = (me, pages) => {
    me.editable = true;
    let numChannelsAnswer = getQuestionAnswer(pages[11], 0)
    if (numChannelsAnswer && numChannelsAnswer.key !== '3') {
        me.setReadableValue('0');
    }
}

let whenWwCwAndFixedCCTSetToGiven = (me, pages, given) => {
    me.editable = true;
    let numChannelsAnswer = getQuestionAnswer(pages[11], 0)
    if (numChannelsAnswer && numChannelsAnswer.key === '1') {
        me.setReadableValue(given.readableValue);
    }
}

let whenBrightnessAndCctPwmsSet0 = (me, pages) => {
    me.editable = true;
    let answer = getQuestionAnswer(pages[4], 0)
    if (answer && answer.key === BRIGHTNESS_CCT_PWMS) {
        me.setReadableValue('0');
    }
}

let whenBrightnessPMWAndWhiteSetToWhiteGpio2 = (me, whiteGpio2, pages) => {
    me.editable = true;
    let answer = getQuestionAnswer(pages[4], 0)
    if (answer && answer.key === BRIGHTNESS_CCT_PWMS) {
        me.setReadableValue(whiteGpio2.readableValue);
    }
}

let whenPWMActiveHighLowIsSet = (me, pages) => {
    me.editable = true;
    let flag0Answer = getQuestionAnswer(pages[5], 1)
    let flag1Answer = getQuestionAnswer(pages[5], 4)
    let flag2Answer = getQuestionAnswer(pages[5], 7)
    let flag3Answer = getQuestionAnswer(pages[12], 1)
    let flag4Answer = getQuestionAnswer(pages[12], 4)
    let flag5Answer = getQuestionAnswer(pages[13], 1)
    let flag6Answer = getQuestionAnswer(pages[3], 1)
    let flag7Answer = getQuestionAnswer(pages[3], 3)
    let flag8Answer = getQuestionAnswer(pages[3], 5)
    let flag = me.readableValue;
    setFlagBit(flag0Answer, flag[0]);
    setFlagBit(flag1Answer, flag[1]);
    setFlagBit(flag2Answer, flag[2]);
    setFlagBit(flag3Answer, flag[3]);
    setFlagBit(flag4Answer, flag[4]);
    setFlagBit(flag5Answer, flag[5]);
    setFlagBit(flag6Answer, flag[6]);
    setFlagBit(flag7Answer, flag[7]);
    setFlagBit(flag8Answer, flag[8]);
    me.setReadableValue(flag);
}

let whenFlagQuestionsAreAnsweredSetValues = (me, pages) => {
    me.editable = true;
    let flag0Answer = getQuestionAnswer(pages[10], 0)
    let flag2Answer = getQuestionAnswer(pages[20], 1)
    let flag3Answer = getQuestionAnswer(pages[15], 2)
    let flag4Answer = getQuestionAnswer(pages[15], 3)
    let flag5Answer = getQuestionAnswer(pages[15], 4)
    let flag = me.readableValue;
    setFlagBit(flag0Answer, flag[0]);
    flag[1].bit = '0';
    flag[6].bit = '0';
    setFlagBit(flag2Answer, flag[2]);
    setFlagBit(flag3Answer, flag[3]);
    setFlagBit(flag4Answer, flag[4]);
    setFlagBit(flag5Answer, flag[5]);

    me.setReadableValue(flag);
}

function setFlagBit(flagAnswer, flag) {
    const originalFlagBit = flag.bit;

    if (!flagAnswer) {
        flag.bit = originalFlagBit;
    } else {
        const isFlagAnswerYes = flagAnswer.key === YES || flagAnswer.key === 'Low';
        if (isFlagAnswerYes || (originalFlagBit === "1" && flagAnswer.key !== NO && flagAnswer.key !== 'High')) {
            flag.bit = '1';
        } else {
            flag.bit = '0';
        }
    }
}

let whenGPIOIsSelectedLookUpOption = (me, ...gpios) => {

    me.editable = true;
    me.possibleValues = me.getPossibleOptions();
    me.selectedvalues = [];  
    gpios.filter(gpio => gpio !== undefined).forEach(gpio => {
        let readableValue = gpio.readableValue;

        if (gpio.readableValue && gpio.readableValue !== "n/a" && gpio.readableValue !== "0") {  
            me.selectedvalues.push(gpio.readableValue);
        }

        if (readableValue !== 'n/a') {
            me.errorMessage = me.readableValue === readableValue & me.errorMessage == null ? `${me.readableValue} has been selected already` : null;
        } else if (readableValue !== 'n/a' && me.readableValue === readableValue) {
            if (me.errorMessage == null)
                me.errorMessage = me.readableValue === readableValue ? `${me.readableValue} has been selected already` : null;
        } else {
            me.errorMessage = null;
        }

    })
}


let whenGPIOIsSelectedRemoveOption = (me, ...gpios) => {
    me.editable = true;
    me.possibleValues = me.getPossibleOptions();
    gpios.filter(gpio => gpio !== undefined).forEach(gpio => {
        filterReadableValue(me, gpio.readableValue)
    })
    let nonHiddenNonMeOptions = me.possibleValues.filter(val =>
        (!val.hidden && val.key !== me.readableValue));

    if (nonHiddenNonMeOptions.length <= 0) {
        me.possibleValues.push({ key: 'Not Available', value: '255' });
    } else {
        me.possibleValues.filter(val => val.value !== '255')
    }
}

let whenGPIOIsSelectedRemoveOptionWhite = (me, pages, white3, white1, ...gpio) => {
    let gpios = [...gpio]
    let numChannelsAnswer = getQuestionAnswer(pages[11], 0);
    let brightnessWhiteAnswer = getQuestionAnswer(pages[4], 0);
    if (numChannelsAnswer && numChannelsAnswer.key === '2') {
        gpios.push(white3);
    } else if (numChannelsAnswer && numChannelsAnswer.key === '3') {
        gpios.push(white1);
        gpios.push(white3);
    }
    if (brightnessWhiteAnswer && brightnessWhiteAnswer.key === BRIGHTNESS_CCT_PWMS) {
        gpios.push(white1);
    }
    whenGPIOIsSelectedRemoveOption(me, ...gpios);
}

let whenPwmsSet0OtherwizeSet14 = (me, pages) => {
    let answer = getQuestionAnswer(pages[0], 0);
    if (answer && answer.key !== PWMS) {
        me.setRawValue('14');
    } else if (answer) {
        me.setRawValue('0A')
    }
}
function filterReadableValue(me, readableValue) {
    if (readableValue !== 'n/a') {
        me.possibleValues = me.possibleValues.filter(val => val.key !== readableValue)
    }
}

let buttonBehavior = (me, pages) => {
    let answer = getQuestionAnswer(pages[15], 5);

    const buttonBehaviors = {
        [NOVOLINK_BUTTON]: 'NOVOLINK_BUTTON',
        [BUTTON_TOGGLES_ON_OFF]: 'LDS_BUTTON',
        [SETUP_MODE_ONLY]: 'SETUP_MODE_ONLY'
    };

    if (answer && buttonBehaviors.hasOwnProperty(answer.key)) {
        me.setReadableValue(buttonBehaviors[answer.key]);
    } else if (!me.readableValue) {
        me.setReadableValue('n/a');
    }
};


let embeddedSequence = (me, buttonBehavior) => {

    if (buttonBehavior.rawValue === '00') {
        me.setRawValue('01');
    } else if (buttonBehavior.rawValue !== '01') {
        me.setReadableValue('n/a');
    }
}

let colorTempOffDelayRule = (me, pages) => {
    let answer = getQuestionAnswer(pages[6], 4);
    if ((me.rawValue !== "00") || (answer && answer.key === YES)) {
        me.setReadableValue('1');
    } else {
        me.setReadableValue('0');
    }
}

let whenMcuChosenThenSet0 = (me, pages) => {
    let answer = getQuestionAnswer(pages[0], 0);
    if (answer && answer.key === MCU_UART_PROTOCOL) {
        me.setReadableValue('0')
    }
}

let mcuParam4 = (me, pages) => {
    let answer = getQuestionAnswer(pages[0], 0);
    let rgbMappingAnswer = getQuestionAnswer(pages[19], 0);
    if (answer && (answer.key === MCU_UART_PROTOCOL || answer.key === PWMS)) {
        me.setRawValue('FF');
    }
    if (rgbMappingAnswer) {
        me.setReadableValue(rgbMappingAnswer);
    }
}

let mcuTxGpio = (me, pages) => {
    let mcuAnswer = getQuestionAnswer(pages[7], 0);
    let i2cAnswer = getQuestionAnswer(pages[18], 1);
    let moduleAnswer = getQuestionAnswer(pages[23], 1);

    if ((mcuAnswer && mcuAnswer.key === GPIO_27) || (i2cAnswer && i2cAnswer.key === GPIO_27)) {
        if (moduleAnswer.key === MODULE_GEN2B) {
            me.setReadableValue('3');
        } else {
            me.setReadableValue('27');
        }
    } else {
        me.setReadableValue('n/a');
    }
}

let mcuRxGpio = (me, pages) => {
    let mcuAnswer = getQuestionAnswer(pages[7], 1);
    let i2cAnswer = getQuestionAnswer(pages[18], 2);
    let moduleAnswer = getQuestionAnswer(pages[23], 1);
    if ((mcuAnswer && mcuAnswer.key === GPIO_25) || (i2cAnswer && i2cAnswer.key === GPIO_25)) {
        if (moduleAnswer.key === MODULE_GEN2B) {
            me.setReadableValue('2');
        } else {
            me.setReadableValue('25');
        }
    } else {
        me.setReadableValue('n/a');
    }
}

let mcuParam1 = (me, pages) => {
    me.editable = true;
    let answer = getQuestionAnswer(pages[7], 3);
    let pwmAnswer = getQuestionAnswer(pages[0], 0);
    if (answer && answer.key === YES) {
        me.setRawValue('02');
    } else if (answer) {
        me.setReadableValue('0');
    } else if (pwmAnswer && pwmAnswer.key === PWMS) {
        me.setRawValue('FF');
    }
}

let mcuParam2 = (me, pages) => {
    me.editable = true;
    let answer = getQuestionAnswer(pages[7], 4);
    let pwmAnswer = getQuestionAnswer(pages[0], 0);
    if (answer && answer.key === YES) {
        me.setRawValue('01');
    } else if (answer) {
        me.setReadableValue('0');
    } else if (pwmAnswer && pwmAnswer.key === PWMS) {
        me.setRawValue('FF');
    }
}

let whenNotNightlightSetNA = (me, pages) => {
    me.editable = true;
    let answer = getQuestionAnswer(pages[16], 0);
    if (answer && answer.key === NO) {
        me.setReadableValue('n/a');
    }
}

let mcuResetGPIO = (me, pages) => {
    me.editable = true;
    let answer = getQuestionAnswer(pages[7], 2);
    let mcuartAnswer = getQuestionAnswer(pages[0], 0);
    if ((me.readableValue === 'n/a') && ((answer && answer.key === NO) || (mcuartAnswer && mcuartAnswer.key !== MCU_UART_PROTOCOL))) {
        me.setReadableValue('n/a')
    }
}

let uartProtocolRule = (me, pages) => {
    let answer = getQuestionAnswer(pages[18], 0);
    let pwmAnswer = getQuestionAnswer(pages[0], 0);
    if (answer && answer.key === BP5768D) {
        me.setRawValue('01');
    } else if (answer && answer.key === BP1658CJ) {
        me.setRawValue('03');
    } else if (pwmAnswer && pwmAnswer.key === PWMS) {
        me.setRawValue('FF');
    }
}

let numControllableRule = (me, pages) => {
    me.editable = true;
    let answer = getQuestionAnswer(pages[0], 0);
    if (answer && (answer.key === PWMS || answer.key === I2C)) {
        me.setRawValue('01');
    }
}

let pwmsInUse = (me, pages) => {
    me.editable = true;
    let answer = getQuestionAnswer(pages[21], 0);
    if (answer && answer.key === NO) {
        me.setReadableValue('n/a');
    }
}

let frequencyRule = (me, pages) => {
    me.editable = true;
    let answer = getQuestionAnswer(pages[0], 0);
    if (answer && answer.key !== PWMS) {
        me.setReadableValue('n/a');
    }
}
let whenNoButtonSetNA = (me, pages) => {
    me.editable = true;
    let answer = getQuestionAnswer(pages[14], 0);
    if (answer && answer.key === NO) {
        me.setReadableValue('n/a');
    }
}

let whenNoButtonGPIOSetNA = (me, pages, questionID) => {
    me.editable = true;
    let rawValue = getQuestionRawValue(pages[15], questionID);
    if (rawValue === undefined || rawValue === "" || rawValue === "NAN") {
        me.setReadableValue('n/a');
        me.setRawValue('FF');
    }
}

let validGpios = (me, pages, showAll = false, isI2CGpio = false, isSDA = false) => {
    let module = getQuestionAnswer(pages[23], 1);
    let mount = getQuestionAnswer(pages[23], 0);
    if (module && mount) {
        me.changePossibleOptions(getPossibleGpios(mount.key, module.key, showAll, isI2CGpio, isSDA));
        me.setRawValue(me.rawValue);
    }
}

let restrictCct = (me, filter, priorGpioValue) => {
    me.editable = true;
    if (filter) {
        if (priorGpioValue.readableValue !== 'n/a') {
            me.possibleValues = me.possibleOptions.filter((gpio) => {
                if (gpio.value === 0) return true;
                return gpio.value > Number(priorGpioValue.readableValue);
            });
        }
    } else {
        me.possibleValues = me.possibleOptions;
    }
}

let restrictCCT2 = (me, pages, priorCct) => {
    let numChannelsAnswer = getQuestionAnswer(pages[11], 0);
    let filter = numChannelsAnswer && numChannelsAnswer.key === '3';
    restrictCct(me, filter, priorCct);
}

let restrictCCT3 = (me, pages, priorCct) => {
    let numChannelsAnswer = getQuestionAnswer(pages[11], 0);
    let filter = numChannelsAnswer && numChannelsAnswer.key !== '1';
    restrictCct(me, filter, priorCct);
}

let getQuestionAnswer = (page, question) => {
    return page?.questions[question]?.answer
}

let getQuestionRawValue = (page, question) => {
    return page.questions[question].config.rawValue
}
let whenPWMsInUseSetI2CNA = (me, pages) => {
    me.editable = true;
    let answer = getQuestionAnswer(pages[0], 0);
    if (answer && answer.key === PWMS) {
        me.setReadableValue('n/a');
        me.setRawValue('FF');
    }
}
export {
    hardCoded, whenWhiteOnlySetNA, whenNotPwmSetNA, whenNotPwmSet0, whenBrightnessAndCctPwmsSet0, whenBrightnessPMWAndWhiteSetToWhiteGpio2,
    whenFlagQuestionsAreAnsweredSetValues, whenGPIOIsSelectedRemoveOption, when3ChannelsSetNa, whenNot3ChannelsSet0, whenPWMActiveHighLowIsSet,
    whenWwCwAndFixedCCTSetToGiven, whenGPIOIsSelectedRemoveOptionWhite, whenPwmsSet0OtherwizeSet14, buttonBehavior, embeddedSequence,
    colorTempOffDelayRule, whenNotNightlightSetNA, whenMcuChosenThenSet0, mcuParam4, mcuTxGpio, mcuRxGpio, mcuParam1, mcuParam2, mcuResetGPIO,
    uartProtocolRule, numControllableRule, pwmsInUse, frequencyRule, whenNoButtonSetNA, validGpios, restrictCct, restrictCCT2, restrictCCT3, whenNoButtonGPIOSetNA, whenPWMsInUseSetI2CNA,whenGPIOIsSelectedLookUpOption
}
