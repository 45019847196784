import React, { Component } from 'react';
import {
    Button,
    Form, Card, FormGroup, Table
} from 'semantic-ui-react';
import EditField from "./EditField";
import {
    QUESTION_TYPES, NIGHTLIGHTGPO, MODULE_SMALLFFV1, MODULE_SMALLFFV2, PHYSICALBUTTONWHENNOGPIO, PHYSICALBUTTONWHENGPIO, NIGHTLIGHTWHENNOGPIO, NIGHTLIGHTWHENGPIO, LIGHTRGBSUPPORT, MODULEUSED
} from "../../../util/encoder/configrules/WizardPages";
import PropTypes from "prop-types";

const YES = 'Yes';
const NO = 'No';
const DEFAULTVALUE = 'n/a';
export default class WizardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            props: props,
            config: props.config,
            hasError: true,
            page: props.page,
            poppedItems: [],
            selected500Mount: "",
            counter: 0,
            selectedWizard500ConfigValues: [],
            selectedWizard501ConfigValues: [],
        }
        if (props.pagesOrder) {
            console.log(props)
            this.state.pagesOrder = props.pagesOrder;
        } else {
            this.state.pagesOrder = [props.page]
        }
        this.state.hasError = this.disableNext(props.page)
    }

    checkForSelectedModuleNames() {
        const element = document.getElementsByName(MODULEUSED);
        if (element.length) {
            if (element[0].checked || element[1].checked || element[2].checked) {
                return true;
            }
            return false;
        }
    }

    enableOrDisableModulesOnMountSelection(param, enableButton = false, question) {
        this.setDataToStateVariable({ [question]: param?.key }, this.state.props.attrid);
        if (this.state.props.attrid !== 500) {
            return;
        }
        let disableSmallFFmodules = false;
        if (param?.key === 'Horizontal' || param === 'Horizontal') {
            disableSmallFFmodules = true;
            this.setState({ selected500Mount: 'Horizontal', hasError: true });
            if (enableButton || this.checkForSelectedModuleNames()) {
                this.setState({ hasError: false });
            }
        }
        else if (param?.key === 'Vertical' || param === 'Vertical') {
            this.setState({ selected500Mount: 'Vertical' });
        }
        else {
            return;
        }
        const element = document.getElementsByName(MODULEUSED);
        if (element.length && element[3] && element[4]) {
            element[3].disabled = disableSmallFFmodules;
            element[4].disabled = disableSmallFFmodules;
        }
    }

    setDataToStateVariable(keyValueInfo, attributeId) {
        const { deviceId } = JSON.parse(localStorage.getItem("selectedDeviceObj"));
        if (!deviceId) return;
        const stateKeyMap = {
            500: 'selectedWizard500ConfigValues',
            501: 'selectedWizard501ConfigValues',
        };
        const stateKey = stateKeyMap[attributeId];
        if (!stateKey) return;
        this.setState(prevState => {
            const updatedValues = [...prevState[stateKey]];
            const existingValuesMap = Object.fromEntries(updatedValues.map(entry => [entry.key, entry]));
            Object.entries(keyValueInfo).forEach(([key, value]) => {
                if (existingValuesMap[key]) {
                    existingValuesMap[key].value = value;
                } else {
                    existingValuesMap[key] = { key, value };
                }
            });
            return { [stateKey]: Object.values(existingValuesMap) };
        });
    }

    setDataToLocalStorage() {
        const { deviceId } = JSON.parse(localStorage.getItem("selectedDeviceObj"));
        localStorage.setItem(`${deviceId}_${this.props.attrid}`, JSON.stringify(this.state.selectedWizard500ConfigValues)); 
    }




    render() {
        return (
            <div>
                <div className="decoder-component" id='decoder_item'>
                    <Form onSubmit={(event => this.next(event))}
                        data-nextwizardpage={this.state.config?.wizardPages[this.state?.page - 1]?.nextPage}>
                        <Card.Group itemsPerRow={1}>
                            {this.state.config.wizardPages[this.state.page - 1].questions.map((question, index) => {
                                if (question?.config && !question?.config?.visible) return <div />
                                return (
                                    <Card id={'decoder_card'} key={this.state.page + '' + index} data-nextpage={question.nextPage}>
                                        <Card.Content>
                                            <Card.Header id={'attributeTitle'}>
                                                {question.question}
                                            </Card.Header>
                                            <Card.Description>
                                                {question.type === QUESTION_TYPES.multi ?
                                                    <div>
                                                        <FormGroup grouped>
                                                            {question?.answers?.map(answer => {
                                                                return (
                                                                    <Form.Field
                                                                        className={'radiobuttons'}
                                                                        key={answer.key}
                                                                        type='radio'
                                                                        label={answer.key}
                                                                        control='input'
                                                                        defaultChecked={(question.answer && question.answer.key === answer.key)}
                                                                        name={question.question}
                                                                        data-answer={JSON.stringify(answer)}
                                                                        onClick={(event) => {
                                                                            question.answer = answer;
                                                                            this.setState({ hasError: this.disableNext(this.state.page) });
                                                                            this.enableOrDisableModulesOnMountSelection(question.answer, false, question?.question)
                                                                        }}
                                                                    />
                                                                )
                                                            })}
                                                        </FormGroup>
                                                    </div>
                                                    : (question.type === QUESTION_TYPES.table) ?
                                                        <div>
                                                            <Table fixed inverted celled compact='very' basic='very'>
                                                                <Table.Header>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell></Table.HeaderCell>
                                                                        {question.columns.map((column, index) => {
                                                                            return (
                                                                                <Table.HeaderCell key={index}>{column}</Table.HeaderCell>
                                                                            )
                                                                        })}
                                                                    </Table.Row>
                                                                </Table.Header>
                                                                <Table.Body>
                                                                    {question.rows.map((row, index) => {
                                                                        return (
                                                                            <Table.Row key={index}>
                                                                                <Table.Cell key={index}>{row}</Table.Cell>
                                                                                {question.columns.map((column, index) => {
                                                                                    return (
                                                                                        <Table.Cell>
                                                                                            <Form.Field
                                                                                                className={'radiobuttons'}
                                                                                                key={row + ':' + column}
                                                                                                type='radio'
                                                                                                control='input'
                                                                                                name={row}
                                                                                                data-column={column}
                                                                                                onClick={event => { this.tableRadioButtonClick(event, question) }}
                                                                                            />
                                                                                        </Table.Cell>

                                                                                    )
                                                                                })}
                                                                            </Table.Row>
                                                                        )
                                                                    })}
                                                                </Table.Body>
                                                            </Table>
                                                        </div>
                                                        :
                                                        <div>

                                                            <EditField
                                                                key={question?.config?.id + 'editField'}
                                                                attribute={question?.config}
                                                                justEdit={question?.config?.editable}
                                                                dropdownChange={(event) => { this.dropdownChange(event, question?.question) }}
                                                                textChange={(event) => this.textChange(event)}
                                                            />
                                                        </div>}
                                            </Card.Description>
                                        </Card.Content>
                                    </Card>
                                )
                            })}
                        </Card.Group>
                        <Card.Content extra>
                            <div className={'backNext'}>
                                <Button data-endform={true} type={'button'} onClick={() => this.previous()} disabled={this.state.pagesOrder.length === 1}>
                                    Back
                                </Button>
                                <Button id='encodeButton' style={{ display: (this.hasNextPage()) ? 'block' : 'none' }}
                                    disabled={this.state.hasError}>
                                    Next
                                </Button>
                                <span style={{ display: (this.hasNextPage()) ? 'none' : 'flex' }}>
                                    {this.props.submitButton(this.state.page, this.state.pagesOrder)}
                                </span>
                            </div>
                        </Card.Content>
                    </Form>
                </div>
            </div>
        );
    }

    checkYesOrNoForQuestion(question, disableYes) {
        const intervalId = setInterval(() => {
            const elements = document.getElementsByName(question);
            if (elements.length > 0) {
                elements[0].disabled = disableYes;
            }
        }, 10)
        setTimeout(() => {
            clearInterval(intervalId);
        }, 1000);
    }

    modifyQuestionsBasedOnPinsAvailability() {
        let modulesPage = this.state.config.wizardPages.filter(page => page.id === 24);
        let channelsPage = this.state.config.wizardPages.filter(page => page.id === 12);
        let physicalButtonPage = this.state.config.wizardPages.filter(page => page.id === 15);

        let nightLightPage = this.state.config.wizardPages.filter(page => page.id === 17);
        let nightLightGPIOPage = this.state.config.wizardPages.filter(page => page.id === 18);


        if ((modulesPage[0].questions[1].answer.key === MODULE_SMALLFFV1 || modulesPage[0].questions[1].answer.key === MODULE_SMALLFFV2)) {
            if (channelsPage[0].questions[0].answer.key === '3') {
                physicalButtonPage[0].questions[0].question = PHYSICALBUTTONWHENNOGPIO;
                this.checkYesOrNoForQuestion(PHYSICALBUTTONWHENNOGPIO, true);
            } else {
                physicalButtonPage[0].questions[0].question = PHYSICALBUTTONWHENGPIO;
                this.checkYesOrNoForQuestion(PHYSICALBUTTONWHENGPIO, false);
            }

            if (nightLightGPIOPage[0].questions[0].config.possibleValues.length === 2 &&
                nightLightGPIOPage[0].questions[0].config.possibleValues[1].key === "Not Available") {
                nightLightPage[0].questions[0].question = NIGHTLIGHTWHENNOGPIO;
                this.checkYesOrNoForQuestion(NIGHTLIGHTWHENNOGPIO, true);
            } else {
                nightLightPage[0].questions[0].question = NIGHTLIGHTWHENGPIO;
                this.checkYesOrNoForQuestion(NIGHTLIGHTWHENGPIO, false);
            }
        }


    }


    disableRGBForNewModules() {
        let modulesPage = this.state.config.wizardPages.filter(page => page.id === 24);
        let moduleAnswer = modulesPage[0].questions[1].answer.key;
        if (moduleAnswer === MODULE_SMALLFFV1 || moduleAnswer === MODULE_SMALLFFV2) {
            for (const item of this.state.config.wizardPages) {
                if (item.id === 2) {
                    setTimeout(() => {
                        const element = document.getElementsByName(LIGHTRGBSUPPORT);
                        if (element.length) {
                            element[0].disabled = true;
                            element[1].checked = true;
                            this.setState({ hasError: false })
                        }
                    }, 10)
                }
            }
        }
    }

    next(event) {
        this.updateTextInputAttributes();
        this.updateDropdownAttributes();
        this.clearPages();
        this.state.config.runRules();
        this.state.config.encode();
        this.loadNextPageData();
        let nextPage = this.whereTo(event.target);
        if (!!nextPage) {
            this.state.pagesOrder.push(nextPage);
            this.setState({ page: nextPage, hasError: this.disableNext(nextPage) });
        } else {
            let hiddenSubmitBtn = event.target.parentElement.querySelector('.hiddenSubmitButton');
            if (hiddenSubmitBtn) {
                hiddenSubmitBtn.click();
            }
        }
        if (this.state.props.attrid === 500) {
            if (nextPage === 15 || nextPage === 17) {
                this.modifyQuestionsBasedOnPinsAvailability();
            }
            this.disableRGBForNewModules();
        }
    }

    loadNextPageData() {

        let isPWMPage = this.state.config.wizardPages.find(page => page.id === 1);

        if (isPWMPage.questions[0].answer && isPWMPage.questions[0].answer.key !== "") {
            if (isPWMPage.questions[0].answer && isPWMPage.questions[0].answer.key === "PWMs") {

                this.loadPWMSModule();
            }

            if (isPWMPage.questions[0].answer && isPWMPage.questions[0].answer.key === "MCU using the Afero UART protocol") {

                this.loadUARTModule();
            }

            if (isPWMPage.questions[0].answer && isPWMPage.questions[0].answer.key === "I2C") {

                let page_I2C = this.state.config.wizardPages.find(page => page.id === 19);
                let I2C_chip_is_being_used = this.state.config.attributes.find(attr => attr.id === 41)

                if (page_I2C.questions[0].answer === undefined || page_I2C.questions[0].answer === "") {
                    if (I2C_chip_is_being_used.readableValue && I2C_chip_is_being_used.readableValue.applicable) {
                        page_I2C.questions[0].answer = { key: "BP1658CJ", nextPage: 20 };
                    } else {
                        page_I2C.questions[0].answer = { key: "BP5768D" };
                    }
                }

            }

            this.preLoadCommonPages();

            this.setState((prevState) => ({
                counter: prevState.counter + 1,
            }));

        }
    }

    loadPWMSModule() {

        let flags = this.state.config.attributes.find(attr => attr.id === 16)
        let PWMs_in_Use = this.state.config.attributes.find(attr => attr.id === 17)

        let page_Question_Flags = this.state.config.wizardPages.find(page => page.id === 11);
        let page_high_gpio_question = this.state.config.wizardPages.find(page => page.id === 22);
        let page_high_gpio_Active_question = this.state.config.wizardPages.find(page => page.id === 21);
        let page_Question_has_RGB = this.state.config.wizardPages.find(page => page.id === 2);


        let one_chanel_gpio = this.state.config.wizardPages.find(attr => attr.id === 14)
        let two_chanel_gpio = this.state.config.wizardPages.find(attr => attr.id === 13)
        let three_chanel_gpio = this.state.config.wizardPages.find(attr => attr.id === 6)
        let rgb_chanel_gpio = this.state.config.wizardPages.find(attr => attr.id === 4)

        let page_Brightness_GPIO_White = this.state.config.wizardPages.find(page => page.id === 7);



        let Red_GPIO = this.state.config.attributes.find(attr => attr.id === 8)
        let GREEN_GPIO = this.state.config.attributes.find(attr => attr.id === 9)
        let BLUE_GPIO = this.state.config.attributes.find(attr => attr.id === 10)

        if (page_Question_Flags.questions[0].answer === "") {

            if (flags.readableValue[0].value === "SUPPORTED") {
                page_Question_Flags.questions[0].answer = { key: YES }
            }
            else {
                page_Question_Flags.questions[0].answer = { key: NO }
            }
        }

        if (one_chanel_gpio.questions[1].answer === "") {
            one_chanel_gpio.questions[1].answer = { key: NO }
        }

        if (two_chanel_gpio.questions[1].answer === "") {
            two_chanel_gpio.questions[1].answer = { key: NO }
        } else if (two_chanel_gpio.questions[4].answer === "") {
            two_chanel_gpio.questions[4].answer = { key: NO }
        }

        if (three_chanel_gpio.questions[1].answer === "") {
            three_chanel_gpio.questions[1].answer = { key: NO }
        } else if (three_chanel_gpio.questions[4].answer === "") {
            three_chanel_gpio.questions[4].answer = { key: NO }
        } else if (three_chanel_gpio.questions[7].answer === "") {
            three_chanel_gpio.questions[7].answer = { key: NO }
        }

        if (rgb_chanel_gpio.questions[1].answer === "") {
            rgb_chanel_gpio.questions[1].answer = { key: NO }
        } else if (rgb_chanel_gpio.questions[3].answer === "") {
            rgb_chanel_gpio.questions[3].answer = { key: NO }
        } else if (rgb_chanel_gpio.questions[5].answer === "") {
            rgb_chanel_gpio.questions[5].answer = { key: NO }
        }

        if ((!page_high_gpio_question.questions[0].answer === "" || !page_high_gpio_question.questions[0].answer) && this.state.counter > 0) {
            if (PWMs_in_Use && PWMs_in_Use.readableValue && PWMs_in_Use.readableValue !== DEFAULTVALUE && PWMs_in_Use !== undefined) {
                page_high_gpio_question.questions[0].answer = { key: YES, nextPage: 21 }
            } else if (PWMs_in_Use.readableValue === DEFAULTVALUE) {
                page_high_gpio_question.questions[0].answer = { key: NO, nextPage: 2 }
            }
        }

        if (page_high_gpio_Active_question.questions[1].answer === "" || page_high_gpio_Active_question.questions[1].answer === DEFAULTVALUE ||  page_high_gpio_Active_question.questions[1].answer === undefined) {
            if (flags.readableValue[2].value === "Active LOW") {
                page_high_gpio_Active_question.questions[1].answer = { key: 'Low' }
            } else {
                page_high_gpio_Active_question.questions[1].answer = { key: 'High' }
            }
        }

        if (page_Question_has_RGB.questions[0].answer === undefined || page_Question_has_RGB.questions[0].answer === "") {
            if ((Red_GPIO && Red_GPIO.readableValue && Red_GPIO.readableValue !== DEFAULTVALUE && Red_GPIO !== undefined) ||
                (GREEN_GPIO && GREEN_GPIO.readableValue && GREEN_GPIO.readableValue !== DEFAULTVALUE && GREEN_GPIO !== undefined) ||
                (BLUE_GPIO && BLUE_GPIO.readableValue && BLUE_GPIO.readableValue !== DEFAULTVALUE && BLUE_GPIO !== undefined)
            ) {
                page_Question_has_RGB.questions[0].answer = { key: 'RGB + White', nextPage: 4 }
            } else {
                page_Question_has_RGB.questions[0].answer = { key: 'White Only', nextPage: 5 }
            }
        }


        let hardware_requires_delay_between = this.state.config.attributes.find(attr => attr.id === 18)

        if ((page_Brightness_GPIO_White.questions[4].answer === "" || page_Brightness_GPIO_White.questions[4].answer === undefined) && this.state.counter > 0) {
            if (hardware_requires_delay_between.rawValue === "01") {
                page_Brightness_GPIO_White.questions[4].answer = { key: YES }
            } else {
                page_Brightness_GPIO_White.questions[4].answer = { key: NO }
            }
        }


    }

    loadUARTModule() {

        let page_uart_protocol = this.state.config.wizardPages.find(page => page.id === 8); //Does your light have a MCU Reset GPIO

        let msurestgpio = this.state.config.attributes.find(attr => attr.id === 29)

        if ((page_uart_protocol.questions[2].answer === undefined || page_uart_protocol.questions[2].answer === "")) {
            if (msurestgpio && msurestgpio.readableValue && msurestgpio.readableValue !== DEFAULTVALUE && msurestgpio !== undefined) {
                page_uart_protocol.questions[2].answer = { key: YES, nextPage: 9 }
            } else if (msurestgpio.readableValue === DEFAULTVALUE) {
                page_uart_protocol.questions[2].answer = { key: NO, nextPage: 23 }
            }
        }

        let MCU_parameter_1 = this.state.config.attributes.find(attr => attr.id === 38)

        if (page_uart_protocol.questions[3].answer === "" || page_uart_protocol.questions[3].answer === undefined) {
            if (MCU_parameter_1 && MCU_parameter_1.readableValue && MCU_parameter_1.rawValue === "02" && (MCU_parameter_1.readableValue !== DEFAULTVALUE && MCU_parameter_1 !== undefined)) {
                page_uart_protocol.questions[3].answer = { key: YES };
            } else if (MCU_parameter_1.readableValue === DEFAULTVALUE || MCU_parameter_1.rawValue !== "02") {
                page_uart_protocol.questions[3].answer = { key: NO };
            }
        }


        let MCU_parameter_2 = this.state.config.attributes.find(attr => attr.id === 39)

        if (page_uart_protocol.questions[4].answer === "" || page_uart_protocol.questions[4].answer === undefined) {
            if (MCU_parameter_2 && MCU_parameter_2.readableValue && MCU_parameter_2.rawValue === "01" && (MCU_parameter_2.readableValue !== DEFAULTVALUE && MCU_parameter_2 !== undefined)) {
                page_uart_protocol.questions[4].answer = { key: YES };
            } else if (MCU_parameter_2.readableValue === DEFAULTVALUE || MCU_parameter_2.rawValue !== "01") {
                page_uart_protocol.questions[4].answer = { key: NO };
            }
        }
    }

    preLoadCommonPages() {

        let Question_Physical_Button = this.state.config.wizardPages.find(page => page.id === 15);
        let flags = this.state.config.attributes.find(attr => attr.id === 16)
        let buttonBehaviorIndex = this.state.config.attributes.find(attr => attr.id === 33)
        let Button_GPIO = this.state.config.attributes.find(attr => attr.id === 31)
        let Button_LED_GPIO = this.state.config.attributes.find(attr => attr.id === 32)

        if (Question_Physical_Button?.questions?.[0]?.answer === "" && this.state.counter > 0) {
            if (((Button_GPIO && Button_GPIO.readableValue && Button_GPIO.readableValue !== DEFAULTVALUE && Button_GPIO !== undefined)) ||
                (Button_LED_GPIO && Button_LED_GPIO.readableValue && Button_LED_GPIO.readableValue !== DEFAULTVALUE && Button_LED_GPIO !== undefined) || (
                    buttonBehaviorIndex && buttonBehaviorIndex.readableValue && buttonBehaviorIndex.readableValue !== DEFAULTVALUE && buttonBehaviorIndex !== undefined)) {
                Question_Physical_Button.questions[0].answer = { key: YES, nextPage: 16 };
            } else if (Button_GPIO.readableValue === DEFAULTVALUE || buttonBehaviorIndex.readableValue === DEFAULTVALUE || Button_LED_GPIO.readableValue === DEFAULTVALUE) {
                Question_Physical_Button.questions[0].answer = { key: NO, nextPage: 17 }
            }
        }

        let Question_Night_Button = this.state.config.wizardPages.find(page => page.id === 17);
        if (Question_Night_Button?.questions[0]?.answer === "" && this.state.counter > 0) {
            let Night_Light_GPIO = this.state.config.attributes.find(attr => attr.id === 42)
            if (Night_Light_GPIO && Night_Light_GPIO.readableValue && Night_Light_GPIO.readableValue !== DEFAULTVALUE && Night_Light_GPIO !== undefined) {
                Question_Night_Button.questions[0].answer = { key: YES, nextPage: 18 };
            } else if (Night_Light_GPIO.readableValue === DEFAULTVALUE) {
                Question_Night_Button.questions[0].answer = { key: NO, nextPage: 3 };
            }
        }


        const NOVOLINK_BUTTON = 'Short-press cycles through multi light presets, medium-press turns the light off, long-press puts the light into setup mode';
        const BUTTON_TOGGLES_ON_OFF = 'Button toggles light on/off';
        const SETUP_MODE_ONLY = 'Used for Setup Mode Only';


        let page_Button_Configuration = this.state.config.wizardPages.find(page => page.id === 16);
        if (page_Button_Configuration) {

        if (page_Button_Configuration.questions && page_Button_Configuration.questions[5].answer === "" && this.state.counter > 0) {
            if (buttonBehaviorIndex && buttonBehaviorIndex.readableValue && (buttonBehaviorIndex.readableValue !== DEFAULTVALUE && buttonBehaviorIndex !== undefined)) {
                if (page_Button_Configuration.questions[5].answer !== DEFAULTVALUE && ((buttonBehaviorIndex.readableValue === "NOVOLINK_BUTTON" || buttonBehaviorIndex.readableValue === `{ key: ${NOVOLINK_BUTTON} }`))) {
                    page_Button_Configuration.questions[5].answer = { key: NOVOLINK_BUTTON };
                } else if (page_Button_Configuration.questions[5].answer !== DEFAULTVALUE && ((buttonBehaviorIndex.readableValue === "LDS_BUTTON" || buttonBehaviorIndex.readableValue === `{ key: ${BUTTON_TOGGLES_ON_OFF} }`))) {
                    page_Button_Configuration.questions[5].answer = { key: BUTTON_TOGGLES_ON_OFF };
                } else if (page_Button_Configuration.questions[5].answer !== DEFAULTVALUE && ((buttonBehaviorIndex.readableValue === "SETUP_MODE_ONLY" || buttonBehaviorIndex.readableValue === `{ key: ${SETUP_MODE_ONLY} }`))) {
                    page_Button_Configuration.questions[5].answer = { key: SETUP_MODE_ONLY };
                }
            }
        } else if (this.state.counter > 0 && Question_Physical_Button.questions[0].answer?.key === NO) {

            page_Button_Configuration.questions[5].answer = "";
            buttonBehaviorIndex.setReadableValue("n/a");
            this.state.config.runRules();
            this.setState({ hasError: this.disableNext(this.state.page) })
        }

        if ((page_Button_Configuration.questions) && (page_Button_Configuration.questions[2].answer === "" || page_Button_Configuration.questions[2].answer === DEFAULTVALUE)) {
            if (page_Button_Configuration.questions[2].answer.key === YES || flags.readableValue[3].value === "Active LOW") {
                page_Button_Configuration.questions[2].answer = { key: YES }
            }
            else {
                page_Button_Configuration.questions[2].answer = { key: NO }
            }
        }

        if ((page_Button_Configuration.questions) && (page_Button_Configuration.questions[3].answer === "" || page_Button_Configuration.questions[3].answer === DEFAULTVALUE)) {
            if (flags.readableValue[4].value === "Active LOW") {
                page_Button_Configuration.questions[3].answer = { key: YES }
            } else {
                page_Button_Configuration.questions[3].answer = { key: NO }
            }
        }

        if ((page_Button_Configuration.questions) && (page_Button_Configuration.questions[4].answer === "" || page_Button_Configuration.questions[4].answer === DEFAULTVALUE)) {
            if (flags.readableValue[5].value === "PROCESS GPIO EVENT(S)") {
                page_Button_Configuration.questions[4].answer = { key: YES }
            } else {
                page_Button_Configuration.questions[4].answer = { key: NO }
            }
        }
      }
    }
    previous() {
        this.state.pagesOrder.pop();
        let prevPage = this.state.pagesOrder[this.state.pagesOrder.length - 1];
        this.setState({ page: prevPage, hasError: this.disableNext(prevPage) });
        if (this.state.props.attrid === 500) {
            this.modifyQuestionsBasedOnPinsAvailability();
            this.disableRGBForNewModules();
            let modulesPage = this.state.config.wizardPages.filter(page => page.id === 24);
            let mountAnswer = modulesPage[0].questions[0].answer.key;
            this.state.config.runRules();
            if (this.state.selected500Mount === 'Horizontal') {
                setTimeout(() => {
                    this.enableOrDisableModulesOnMountSelection(mountAnswer, true)
                }, 10)
            }
        }
    }

    hasNextPage() {
        let currentPage = this.state.config.wizardPages[this.state.page - 1];
        if (!!currentPage.nextPage) return true;
        let questionHasNextPage = false;
        currentPage.questions.forEach(question => {
            question.answers?.forEach(answer => {
                if (answer.hasOwnProperty('nextPage'))
                    questionHasNextPage = true;
            })
        })

        this.state.hasError = currentPage.questions.some(question => question.config?.errorMessage);
        return !!questionHasNextPage;

    }

    whereTo(target) {
        for (let i = 0; i < target.length; i++) {
            if (target.elements[i].type !== 'radio' || !target.elements[i].checked) {
                continue;
            }
            if (target.elements[i].dataset.answer && JSON.parse(target.elements[i].dataset.answer).nextPage) {
                return Number(JSON.parse(target.elements[i].dataset.answer).nextPage);
            }
        }
        return Number(target.dataset.nextwizardpage);
    }

    updateTextInputAttributes() {
        let inputs = document.querySelectorAll('#configInput');
        for (let i = 0; i < inputs.length; i++) {
            let matchAttribute = JSON.parse(inputs[i].parentElement.dataset.attribute);
            if (inputs[i].value === '') {
                inputs[i].value = 'n/a';
            }
            this.updateAttribute(matchAttribute, inputs[i].value)
        }
    }

    updateDropdownAttributes() {
        let dropdowns = document.querySelectorAll('#configDropdown');
        for (let i = 0; i < dropdowns.length; i++) {
            let matchAttribute = JSON.parse(dropdowns[i].dataset.attribute);
            let selectedValue = dropdowns[i].firstChild.textContent.includes("-") ? dropdowns[i].firstChild.textContent.split("-")[0].trim() : dropdowns[i].firstChild.textContent;
            this.updateAttribute(matchAttribute, selectedValue);
        }
    }

    updateAttribute(matchAttribute, input) {
        if (typeof matchAttribute === 'string') matchAttribute = JSON.parse(matchAttribute)
        let attribute = this.state.config.findAttribute(matchAttribute.id);
        attribute.setReadableValue(input);
    }

    disableNext(page) {
        let questions = this.state.config.wizardPages[page - 1].questions;
        let questionsAnswered = true;
        questions.forEach(question => {
            if (question.type === QUESTION_TYPES.multi && !question.answer) {
                questionsAnswered = false;
            }
            if (question.type === QUESTION_TYPES.config && question?.config?.possibleValues) {
                if (!question.config.possibleValues.some(e => e.key === question.config.readableValue)) {
                    questionsAnswered = false;
                }
            } else if (question.type === QUESTION_TYPES.config) {
                if (!this.isHex(question?.config?.rawValue) || (question?.config?.validate && question?.config?.readableValue
                    && question.config.validate(question.config.readableValue, question.config.bytes))) {
                    questionsAnswered = false;
                }
            }
            if (question.type === QUESTION_TYPES.table) {
                if (!question.answer || !question.answer.red || !question.answer.green || !question.answer.blue) {
                    questionsAnswered = false;
                }
            }
            if (question.type === QUESTION_TYPES.config &&
                (question?.question === NIGHTLIGHTGPO || question?.question.indexOf('Button') > -1)) {
                questionsAnswered = true;
            }
        })
        this.disableSubmitButton(!questionsAnswered);
        if (questionsAnswered) {
            questionsAnswered = !(questions.some(question => question.config?.errorMessage));
        }
        return !questionsAnswered;
    }

    isHex(input) {
        var regex = /[0-9A-Fa-f]{2}/g;
        if (typeof input !== 'string') {
            for (let i = 0; i < input?.length; i++) {
                if (!input[i].match(regex)) {
                    return false;
                }
            }
            return true;
        }
        return input.match(regex);
    }

    dropdownChange(event, question) {
        let dropdown = event.target.parentElement.parentElement.parentElement.parentElement.querySelector('#configDropdown');
        let selectedValue = dropdown.firstChild.textContent.includes("-") ? dropdown.firstChild.textContent.split("-")[0].trim() : dropdown.firstChild.textContent;
        this.updateAttribute(dropdown.dataset.attribute, selectedValue);
        this.state.config.runRules();
        this.setState({ hasError: this.disableNext(this.state.page) })
        this.setDataToStateVariable({ [question]: selectedValue }, this.state.props.attrid)
    }

    textChange(event) {
        this.updateAttribute(event.target.parentElement.dataset.attribute, event.target.value)
        this.setState({ hasError: this.disableNext(this.state.page) })
    }

    clearPages() {
        this.state.config.wizardPages.forEach(page => {
            if (page.questions[0].answer && !this.state.pagesOrder.includes(page.id)) {
                page.questions.forEach(question => question.answer = '')
            }
        })
    }

    disableSubmitButton(isDisabled) {
        let submitButton = document.querySelector('.hiddenSubmitButton');
        if (submitButton) {
            submitButton.disabled = isDisabled;
        }
    }

    tableRadioButtonClick(event, question) {
        question.config.readableValue.applicable = true;
        if (event.target.name.toLowerCase().includes('red')) {
            question.config.readableValue.red = event.target.dataset.column;
        } else if (event.target.name.toLowerCase().includes('green')) {
            question.config.readableValue.green = event.target.dataset.column;
        } else if (event.target.name.toLowerCase().includes('blue')) {
            question.config.readableValue.blue = event.target.dataset.column;
        }
        question.answer = question.config.readableValue;
        this.setState({ hasError: this.disableNext(this.state.page) });
    }
}

WizardComponent.propTypes = {
    //This is the id for the starting page of the wizard
    page: PropTypes.number.isRequired,
    //This is an object that needs to contain
    // 1. wizardPages: an array of wizard pages
    // 2. runRules: an array of rules
    // 3. encode: a function that will encode the attributes into a hex string
    // 4. findAttribute: a function that will find an attribute by id
    config: PropTypes.any.isRequired,
    //This function should contain the button component that will be shown on the last page of the wizard.
    //If the wizard doesn't have a designated final page then the submit button should have the class 'hiddenSubmitButton'
    submitButton: PropTypes.func.isRequired
};