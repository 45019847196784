import ConfigAttribute, { EncodeTypes } from "./ConfigAttribute";
import ConfigDecode from "./ConfigDecode";
import { pages500 } from "./WizardPages";
import {
    whenWhiteOnlySetNA,
    whenNotPwmSet0,
    whenNotPwmSetNA,
    whenBrightnessAndCctPwmsSet0,
    whenBrightnessPMWAndWhiteSetToWhiteGpio2,
    whenFlagQuestionsAreAnsweredSetValues,
    whenPWMActiveHighLowIsSet,
    whenGPIOIsSelectedRemoveOption,
    whenWwCwAndFixedCCTSetToGiven,
    whenGPIOIsSelectedRemoveOptionWhite,
    whenPwmsSet0OtherwizeSet14,
    hardCoded,
    buttonBehavior,
    embeddedSequence,
    colorTempOffDelayRule,
    when3ChannelsSetNa,
    whenNot3ChannelsSet0,
    whenNotNightlightSetNA,
    whenMcuChosenThenSet0,
    mcuParam1,
    mcuParam2,
    mcuResetGPIO,
    uartProtocolRule,
    mcuParam4,
    numControllableRule,
    pwmsInUse,
    frequencyRule,
    whenNoButtonSetNA,
    whenNoButtonGPIOSetNA,
    validGpios, restrictCCT3, restrictCCT2,
    whenPWMsInUseSetI2CNA,
    whenGPIOIsSelectedLookUpOption
} from "./Config500WizardRules";
import * as Gpios from "./Gpios";

let rgbMixing = [{ key: "DISABLED", value: 0 }, { key: "ETI", value: 1 }, { key: "LDS_RGBW_A19", value: 2 },
{ key: "LDS_RGBW_BR30", value: 3 }, { key: "LDS_RGBW_A21", value: 4 }, { key: "LDS_RGBW_PAR38", value: 5 },
{ key: "LDS_RGBW_A19_SMD", value: 6 }, { key: "LDS_RGBW_FILAMENT", value: 7 }, { key: "LDS_RGBW_A21_SMD", value: 8 },
{ key: "LDS_RGBW_A19_V2", value: 9 }];
let cctTableOptions = [{ key: "None", value: 0 }, { key: "LDS_FLUSHMOUNT", value: 1 }, { key: "LDS_A19", value: 2 },
{ key: "ETI", value: 3 }, { key: "LDS_RGBW", value: 4 }, { key: "LDS_PUFF", value: 5 }, { key: "LDS_SHOP", value: 6 },
{ key: "LDS_WRAP", value: 7 }, { key: "LDS_SMD", value: 8 }, { key: "LDS_AMB", value: 9 }, { key: "LDS_CLR", value: 10 },
{ key: "EAGLERISE_PATHLIGHT", value: 11 }, { key: "CORDELIA_TRACKLIGHT", value: 12 }, { key: "LDS_WHT_1", value: 13 },
{ key: "LDS_WHT_2", value: 14 }, { key: "LDS_WHT_3", value: 15 }, { key: "LDS_WHT_4", value: 16 }, { key: "LDS_WHT_5", value: 17 },
{ key: "LDS_WHT_6", value: 18 }, { key: "BRIGHT_BOLLARD", value: 19 }, { key: "LDS_TRACKLIGHT", value: 20 },
{ key: "LDS_SMD_A21", value: 21 }, { key: "LDS_SMD_2", value: 22 }, { key: "LDS_SMD_3", value: 23 }];
let uartProtocols = [{ key: 'n/a', value: 255 }, { key: "NOVOLINK_MCU", value: 0 }, { key: "LDS_I2C_MCU", value: 1 }, { key: "YANKON_I2C_MCU", value: 2 }];
let btnBehaviorIndex = [{ key: 'n/a', value: 255 }, { key: "NOVOLINK_BUTTON", value: 0 }, { key: "LDS_BUTTON", value: 1 }, { key: "SETUP_MODE_ONLY", value: 2 }];
let embSeqIndex = [{ key: 'n/a', value: 255 }, { key: "NOVOLINK_INDEX", value: 0 }, { key: "LEDUP_INDEX", value: 1 }, { key: "ELEGA_INDEX", value: 2 }];
let moduleTypeIndex = [{ key: 'n/a', value: 255, hidden: true }, { key: "LA02301", value: 0 }, { key: "SLM22011", value: 1 }, { key: "SLM22012", value: 2 },
{ key: "SLM22013", value: 3 }, { key: "SLM22014", value: 4 }];
let irRemoteMappingIndex = [{ key: 'Original Linear Light', value: 0 }, { key: 'n/a', value: 'n/a' }];
let flags = [{ "name": "Simultaneous white & color:", "trueLabel": "SUPPORTED", "falseLabel": "NOT SUPPORTED" },
{ "name": "IR parser used:", "trueLabel": "NEC", "falseLabel": "RC5" },
{ "name": "PWM Logic:", "trueLabel": "Active LOW", "falseLabel": "Active HIGH" },
{ "name": "Button Logic:", "trueLabel": "Active LOW", "falseLabel": "Active HIGH" },
{ "name": "Button LED Logic:", "trueLabel": "Active LOW", "falseLabel": "Active HIGH" },
{ "name": "Button Press Logic:", "trueLabel": "PROCESS GPIO EVENT(S)", "falseLabel": "NORMAL" },
{ "name": "Pin State Saving:", "trueLabel": "DO NOT SAVE", "falseLabel": "NORMAL" }];
let activeHighLowFlags = [{name: 0, value: 0}, {name: 1, value: 1}, {name: 2, value: 2}, {name: 3, value: 3}, {name: 4, value: 4}, {name: 5, value: 5},{name: 6, value: 6},{name: 7, value: 7},{name: 8, value: 8}];
let cctValues = [{ key: 'n/a', value: 0, hidden: true }, { key: '2200', value: 2200 }, { key: '2300', value: 2300 }, { key: '2400', value: 2400 }, { key: '2500', value: 2500 }, { key: '2600', value: 2600 }, { key: '2700', value: 2700 }, { key: '2800', value: 2800 }, { key: '2900', value: 2900 },
{ key: '3000', value: 3000 }, { key: '3100', value: 3100 }, { key: '3200', value: 3200 }, { key: '3300', value: 3300 }, { key: '3400', value: 3400 }, { key: '3500', value: 3500 }, { key: '3600', value: 3600 }, { key: '3700', value: 3700 }, { key: '3800', value: 3800 }, { key: '3900', value: 3900 },
{ key: '4000', value: 4000 }, { key: '4100', value: 4100 }, { key: '4200', value: 4200 }, { key: '4300', value: 4300 }, { key: '4400', value: 4400 }, { key: '4500', value: 4500 }, { key: '4600', value: 4600 }, { key: '4700', value: 4700 }, { key: '4800', value: 4800 }, { key: '4900', value: 4900 },
{ key: '5000', value: 5000 }, { key: '5100', value: 5100 }, { key: '5200', value: 5200 }, { key: '5300', value: 5300 }, { key: '5400', value: 5400 }, { key: '5500', value: 5500 }, { key: '5600', value: 5600 }, { key: '5700', value: 5700 }, { key: '5800', value: 5800 }, { key: '5900', value: 5900 },
{ key: '6000', value: 6000 }, { key: '6100', value: 6100 }, { key: '6200', value: 6200 }, { key: '6300', value: 6300 }, { key: '6400', value: 6400 }, { key: '6500', value: 6500 }];
const component500 = [
    new ConfigAttribute(1, 'Version', EncodeTypes.Decimal, 'Version of the config attribute'),
    new ConfigAttribute(2, ['White GPIO', 'Brightness PWM'], EncodeTypes.LimitedSelection, 'Select if Brightness PWM is needed. GPIO of lowest white color temperature.', Gpios.ALL),
    new ConfigAttribute(3, 'Function', EncodeTypes.LimitedSelection, 'Function', cctValues, 2),
    new ConfigAttribute(4, 'White GPIO', EncodeTypes.LimitedSelection, 'GPIO of white color temperature or n/a', Gpios.ALL),
    new ConfigAttribute(5, 'Function', EncodeTypes.LimitedSelection, 'Function', cctValues, 2),
    new ConfigAttribute(6, 'White GPIO', EncodeTypes.LimitedSelection, 'GPIO of white color temperature or n/a', Gpios.ALL),
    new ConfigAttribute(7, 'Function', EncodeTypes.LimitedSelection, 'Function', cctValues, 2),
    new ConfigAttribute(8, 'Red GPIO #', EncodeTypes.LimitedSelection, 'ESP32 GPIO # for RED LED', Gpios.ALL),
    new ConfigAttribute(9, 'Green GPIO #', EncodeTypes.LimitedSelection, 'ESP32 GPIO # for GREEN LED', Gpios.ALL),
    new ConfigAttribute(10, 'Blue GPIO #', EncodeTypes.LimitedSelection, 'ESP32 GPIO # for BLUE LED', Gpios.ALL),
    new ConfigAttribute(11, 'Minimum Brightness', EncodeTypes.Percent, 'Minimum Brightness the bulb will go down to'),
    new ConfigAttribute(12, 'CCT/RGB Mixing', EncodeTypes.LimitedSelection, 'Colour Temp RGB Mixing', rgbMixing),
    new ConfigAttribute(13, 'Temperature Limit', EncodeTypes.Celsius, 'The temperature (in Celsius) at which point we\'ll start enforcing a power limit to keep the module operating within it\'s temperature spec range.'),
    new ConfigAttribute(14, 'IR Remote GPIO Num', EncodeTypes.GPIO, 'ESP32 GPIO # for IR remote'),
    new ConfigAttribute(15, 'IR Remote Mapping Index', EncodeTypes.LimitedSelection, 'Mapping to use for IR commands', irRemoteMappingIndex),
    new ConfigAttribute(16, 'Flags', EncodeTypes.Flags, 'Flags', flags),
    new ConfigAttribute(17, 'PWMs in Use', EncodeTypes.LimitedSelection, 'GPIO # to drive HIGH if PWMs in use', Gpios.ALL),
    new ConfigAttribute(18, 'Color Temp Off Delay', EncodeTypes.Seconds, 'Color-off temp delay'),
    new ConfigAttribute(19, 'CCT Low', EncodeTypes.Percent, 'CCT low limit'),
    new ConfigAttribute(20, 'CCT High', EncodeTypes.Percent, 'CCT high limit'),
    new ConfigAttribute(21, 'Red Multiplier', EncodeTypes.Percent, 'Red Multiplier'),
    new ConfigAttribute(22, 'Green Multiplier', EncodeTypes.Percent, 'Green Multiplier'),
    new ConfigAttribute(23, 'Blue Multiplier', EncodeTypes.Percent, 'Blue Multiplier'),
    new ConfigAttribute(24, 'CCT Table', EncodeTypes.LimitedSelection, 'CCT table to use', cctTableOptions),
    new ConfigAttribute(25, 'Ramp Step Size', EncodeTypes.MSeconds, 'How big is our step size when we\'re ramping between values (in ms)'),
    new ConfigAttribute(26, 'MCU UART Protocol', EncodeTypes.LimitedSelection, 'MCU UART Protocol Index', uartProtocols),
    new ConfigAttribute(27, 'MCU UART Tx GPIO', EncodeTypes.LimitedSelection, 'MCU UART TX GPIO Num', Gpios.ALL),
    new ConfigAttribute(28, 'MCU UART Rx GPIO', EncodeTypes.LimitedSelection, 'MCU UART RX GPIO Num', Gpios.ALL),
    new ConfigAttribute(29, 'MCU Reset GPIO', EncodeTypes.LimitedSelection, 'MCU Reset GPIO Num', Gpios.ALL),
    new ConfigAttribute(30, 'Num Controllable', EncodeTypes.Decimal, 'Controllable light count'),
    new ConfigAttribute(31, 'Button GPIO', EncodeTypes.LimitedSelection, 'Button GPIO Num', Gpios.ALL),
    new ConfigAttribute(32, 'Button LED GPIO', EncodeTypes.LimitedSelection, 'Button LED GPIO Num', Gpios.ALL),
    new ConfigAttribute(33, 'Button Behaviour Index', EncodeTypes.LimitedSelection, 'Button Behaviour Index', btnBehaviorIndex),
    new ConfigAttribute(34, 'Embedded Sequence Index', EncodeTypes.LimitedSelection, 'Embedded Sequence Index', embSeqIndex),
    new ConfigAttribute(35, 'Over Temp Extreme Threshold', EncodeTypes.Celsius, 'Over Temp Extreme Threshold in C'),
    new ConfigAttribute(36, 'Over Temp Extreme Threshold Multiplier', EncodeTypes.Percent, 'Over Temp Extreme Threshold Min Multiplier'),
    new ConfigAttribute(37, 'Temp Limit Multiplier', EncodeTypes.Percent, 'Temp Limit Min Multiplier'),
    new ConfigAttribute(38, 'MCU parameter 1', EncodeTypes.Decimal, 'Generic params so MCUs can have additional config values'),
    new ConfigAttribute(39, 'MCU parameter 2', EncodeTypes.Decimal, 'Generic params so MCUs can have additional config values'),
    new ConfigAttribute(40, 'MCU parameter 3', EncodeTypes.Decimal, 'Generic params so MCUs can have additional config values'),
    new ConfigAttribute(41, 'MCU parameter 4', EncodeTypes.RGB, 'Generic params so MCUs can have additional config values'),
    new ConfigAttribute(42, 'Nightlight GPIO', EncodeTypes.LimitedSelection, 'Nightlight GPIO Num', Gpios.ALL),
    new ConfigAttribute(43, 'Nightlight Brightness', EncodeTypes.Percent, 'Nightlight Brightness Num'),
    new ConfigAttribute(44, 'PWM Frequency', EncodeTypes.Frequency, 'PWM Frequency', null, 2),
    new ConfigAttribute(45, 'Module Type', EncodeTypes.LimitedSelection, 'Module Type', moduleTypeIndex),
    new ConfigAttribute(46, 'PWM Active Low Mask', EncodeTypes.Flags, 'PWM Active Low Mask', activeHighLowFlags)
];

class Config500Obj extends ConfigDecode {

    constructor(configValue) {
        super(configValue, component500);
        this.wizardPages = pages500(this.attributes);
        this.setRules();
    }

    decode() {
        let arrayIndex = 0;
        for (let configAttrIndex = 0; configAttrIndex < this.attributes.length; configAttrIndex++, arrayIndex++) {
            if (this.splitConfigValue.length <= arrayIndex) {
                this.#getEmptyAttribute(this.attributes[configAttrIndex]);
                if (this.attributes[configAttrIndex].id === 11) {
                    this.attributes[configAttrIndex].readableValue = '5%'
                }
                continue;
            }
            let calculateRawValue = this._buildConfigAttributeValues(this.attributes[configAttrIndex], arrayIndex);
            if (calculateRawValue.numOfAttributesFromComponent > 1) {
                arrayIndex++;
            }
            this.attributes[configAttrIndex].rawValue = calculateRawValue.rawValue;
            this.attributes[configAttrIndex].readableValue = calculateRawValue.readableValue;
        }
    }

    encode() {
        let configAttrString = '';
        let attributes = this.attributes;
        attributes.forEach(attribute => {
            configAttrString = configAttrString.concat(attribute.rawValue);
        });
        configAttrString = configAttrString.replace(/,/g, '');
        this.configValue = configAttrString;
        this.splitConfigValue = !!configAttrString.match(/(..?)/g) ? configAttrString.match(/(..?)/g) : [];
    }

    #getEmptyAttribute(attribute) {
        if (attribute.defaultValue) {
            attribute.setReadableValue(attribute.defaultValue);
        } else if (attribute.encodeType.encode === EncodeTypes.Flags.encode) {
            attribute.setRawValue('00');
        } else if (attribute.possibleValues) {
            attribute.setReadableValue(attribute.possibleValues[0].key);
        } else if (attribute.encodeType.encode === EncodeTypes.RGB.encode) {
            attribute.setReadableValue({ applicable: false })
        } else {
            attribute.setReadableValue('0');
        }
        return attribute;
    }

    runRules() {
        this.attributes.forEach(attribute => {
            attribute.runRules();
        })
    }

    setRules() {
        let version = this.findAttribute(1);
        let white1GPIO = this.findAttribute(2);
        let white1CCT = this.findAttribute(3);
        let white2GPIO = this.findAttribute(4);
        let white2CCT = this.findAttribute(5);
        let white3GPIO = this.findAttribute(6);
        let white3CCT = this.findAttribute(7);
        let gpioRed = this.findAttribute(8);
        let gpioGreen = this.findAttribute(9);
        let gpioBlue = this.findAttribute(10);
        let cctMixing = this.findAttribute(12);
        let tempLimit = this.findAttribute(13);
        let irRemoteGpioNum = this.findAttribute(14);
        let irRemoteMappingIndex = this.findAttribute(15);
        let flagAttr = this.findAttribute(16);
        let pwmsInUseAttr = this.findAttribute(17);
        let colorTempOffDelay = this.findAttribute(18);
        let cctLow = this.findAttribute(19);
        let cctHigh = this.findAttribute(20);
        let redMultiplier = this.findAttribute(21);
        let greenMultiplier = this.findAttribute(22);
        let blueMultiplier = this.findAttribute(23);
        let cctTable = this.findAttribute(24);
        let rampStepSize = this.findAttribute(25);
        let numControllable = this.findAttribute(30);
        let btnGpio = this.findAttribute(31);
        let btnLedGpio = this.findAttribute(32);
        let buttonBehaviorIndex = this.findAttribute(33);
        let embeddedSequenceIndex = this.findAttribute(34);
        let overTempExtremeThreshold = this.findAttribute(35);
        let overTempExtremeThresholdMinMultiplier = this.findAttribute(36);
        let tempLimitMinMultiplier = this.findAttribute(37);

        let uartProtocolAttr = this.findAttribute(26);
        let mcuTxGpioAttr = this.findAttribute(27);
        let mcuRxGpioAttr = this.findAttribute(28);
        let mcuResetGpioAttr = this.findAttribute(29);
        let mcuParam1Attr = this.findAttribute(38);
        let mcuParam2Attr = this.findAttribute(39);
        let mcuParam3Attr = this.findAttribute(40);
        let mcuParam4Attr = this.findAttribute(41);
        let nightlightGPIO = this.findAttribute(42);
        let frequency = this.findAttribute(44);
        let moduleType = this.findAttribute(45);
        let pwmActiveHighLow = this.findAttribute(46);

        let isI2CGpio = true;

        this.bindRule(hardCoded, version, ['1']);
        this.bindRule(whenWhiteOnlySetNA, gpioRed, [this.wizardPages]);
        this.bindRule(whenWhiteOnlySetNA, gpioGreen, [this.wizardPages]);
        this.bindRule(whenWhiteOnlySetNA, gpioBlue, [this.wizardPages]);
        this.bindRule(whenNotPwmSetNA, gpioRed, [this.wizardPages]);
        this.bindRule(whenNotPwmSetNA, gpioGreen, [this.wizardPages]);
        this.bindRule(whenNotPwmSetNA, gpioBlue, [this.wizardPages]);
        this.bindRule(whenNotPwmSetNA, white1GPIO, [this.wizardPages]);
        this.bindRule(when3ChannelsSetNa, white1GPIO, [this.wizardPages]);
        this.bindRule(whenNotPwmSetNA, white2GPIO, [this.wizardPages]);
        this.bindRule(whenNotPwmSetNA, white3GPIO, [this.wizardPages]);
        this.bindRule(whenWwCwAndFixedCCTSetToGiven, white3GPIO, [this.wizardPages, white2GPIO]);
        this.bindRule(whenWwCwAndFixedCCTSetToGiven, white3CCT, [this.wizardPages, white2CCT]);
        this.bindRule(whenBrightnessPMWAndWhiteSetToWhiteGpio2, white3GPIO, [white2GPIO, this.wizardPages]);
        this.bindRule(whenNotPwmSet0, white1CCT, [this.wizardPages]);
        this.bindRule(whenNot3ChannelsSet0, white1CCT, [this.wizardPages]);
        this.bindRule(whenBrightnessAndCctPwmsSet0, white1CCT, [this.wizardPages]);
        this.bindRule(hardCoded, tempLimit, ['100']);
        this.bindRule(hardCoded, irRemoteGpioNum, ['n/a']);
        this.bindRule(hardCoded, irRemoteMappingIndex, ['n/a']);
        this.bindRule(whenFlagQuestionsAreAnsweredSetValues, flagAttr, [this.wizardPages]);
        this.bindRule(validGpios, gpioRed, [this.wizardPages]);
        this.bindRule(validGpios, gpioGreen, [this.wizardPages]);
        this.bindRule(validGpios, gpioBlue, [this.wizardPages]);
        this.bindRule(validGpios, white1GPIO, [this.wizardPages]);
        this.bindRule(validGpios, white2GPIO, [this.wizardPages]);
        this.bindRule(validGpios, white3GPIO, [this.wizardPages]);
        this.bindRule(validGpios, btnGpio, [this.wizardPages]);
        this.bindRule(validGpios, btnLedGpio, [this.wizardPages]);
        this.bindRule(validGpios, nightlightGPIO, [this.wizardPages]);
        this.bindRule(validGpios, mcuTxGpioAttr, [this.wizardPages, true, isI2CGpio, true]);
        this.bindRule(validGpios, mcuRxGpioAttr, [this.wizardPages, true, isI2CGpio, false]);
        this.bindRule(validGpios, mcuResetGpioAttr, [this.wizardPages]);
        this.bindRule(validGpios, pwmsInUseAttr, [this.wizardPages, true]);
        this.bindRule(whenGPIOIsSelectedRemoveOption, gpioGreen, [gpioRed, gpioBlue]);
        this.bindRule(whenGPIOIsSelectedRemoveOption, gpioRed, [gpioGreen, gpioBlue]);
        this.bindRule(whenGPIOIsSelectedRemoveOption, gpioBlue, [gpioGreen, gpioRed]);
        this.bindRule(whenGPIOIsSelectedRemoveOption, white1GPIO, [white2GPIO, white3GPIO, gpioGreen, gpioBlue, gpioRed]);
        this.bindRule(whenGPIOIsSelectedRemoveOptionWhite, white2GPIO, [this.wizardPages, white3GPIO, white1GPIO, gpioGreen, gpioBlue, gpioRed]);
        this.bindRule(whenGPIOIsSelectedRemoveOption, white3GPIO, [white2GPIO, white1GPIO, gpioGreen, gpioBlue, gpioRed]);
        this.bindRule(whenGPIOIsSelectedRemoveOption, nightlightGPIO, [gpioGreen, gpioBlue, gpioRed, white1GPIO, white2GPIO, white3GPIO, btnGpio, btnLedGpio, mcuTxGpioAttr, mcuRxGpioAttr]);


        this.bindRule(whenNoButtonSetNA, btnGpio, [this.wizardPages]);
        this.bindRule(whenNoButtonSetNA, btnLedGpio, [this.wizardPages]);
        this.bindRule(whenNoButtonGPIOSetNA, btnGpio, [this.wizardPages, 0]);
        this.bindRule(whenNoButtonGPIOSetNA, btnLedGpio, [this.wizardPages, 1]);
        this.bindRule(hardCoded, cctLow, ['0']);
        this.bindRule(hardCoded, cctHigh, ['100']);
        this.bindRule(hardCoded, redMultiplier, ['100']);
        this.bindRule(hardCoded, greenMultiplier, ['100']);
        this.bindRule(hardCoded, blueMultiplier, ['100']);
        this.bindRule(hardCoded, cctTable, ['0']);
        this.bindRule(hardCoded, cctMixing, ['0']);
        this.bindRule(whenPwmsSet0OtherwizeSet14, rampStepSize, [this.wizardPages]);
        this.bindRule(hardCoded, overTempExtremeThreshold, ['110']);
        this.bindRule(hardCoded, overTempExtremeThresholdMinMultiplier, ['70']);
        this.bindRule(hardCoded, tempLimitMinMultiplier, ['90']);
        this.bindRule(hardCoded, moduleType, ['0']);
        this.bindRule(buttonBehavior, buttonBehaviorIndex, [this.wizardPages]);
        this.bindRule(embeddedSequence, embeddedSequenceIndex, [buttonBehaviorIndex]);
        this.bindRule(colorTempOffDelayRule, colorTempOffDelay, [this.wizardPages]);
        this.bindRule(whenNotNightlightSetNA, nightlightGPIO, [this.wizardPages]);
        this.bindRule(pwmsInUse, pwmsInUseAttr, [this.wizardPages]);
        this.bindRule(whenNotPwmSetNA, pwmsInUseAttr, [this.wizardPages]);
        this.bindRule(whenMcuChosenThenSet0, uartProtocolAttr, [this.wizardPages]);
        this.bindRule(uartProtocolRule, uartProtocolAttr, [this.wizardPages]);
        this.bindRule(hardCoded, mcuParam3Attr, ['255']);
        this.bindRule(mcuParam4, mcuParam4Attr, [this.wizardPages]);
        this.bindRule(mcuParam1, mcuParam1Attr, [this.wizardPages]);
        this.bindRule(mcuParam2, mcuParam2Attr, [this.wizardPages]);
        this.bindRule(mcuResetGPIO, mcuResetGpioAttr, [this.wizardPages]);
        this.bindRule(numControllableRule, numControllable, [this.wizardPages]);
        this.bindRule(frequencyRule, frequency, [this.wizardPages]);
        this.bindRule(restrictCCT2, white2CCT, [this.wizardPages, white1CCT]);
        this.bindRule(restrictCCT3, white3CCT, [this.wizardPages, white2CCT]);     
        this.bindRule(whenGPIOIsSelectedLookUpOption, mcuTxGpioAttr, [mcuRxGpioAttr]);
        this.bindRule(whenGPIOIsSelectedLookUpOption, mcuRxGpioAttr, [mcuTxGpioAttr]);
        this.bindRule(whenPWMsInUseSetI2CNA, mcuTxGpioAttr, [this.wizardPages]);
        this.bindRule(whenPWMsInUseSetI2CNA, mcuRxGpioAttr, [this.wizardPages]);

        this.bindRule(whenGPIOIsSelectedLookUpOption, btnGpio, [btnLedGpio]);
        this.bindRule(whenGPIOIsSelectedLookUpOption, btnLedGpio, [btnGpio]);
        this.bindRule(whenGPIOIsSelectedRemoveOption, btnGpio, [gpioGreen, gpioBlue, gpioRed, white1GPIO, white2GPIO, white3GPIO, mcuTxGpioAttr, mcuRxGpioAttr]);
        this.bindRule(whenGPIOIsSelectedRemoveOption, btnLedGpio, [gpioGreen, gpioBlue, gpioRed, white1GPIO, white2GPIO, white3GPIO, mcuTxGpioAttr, mcuRxGpioAttr]);

        this.bindRule(whenPWMActiveHighLowIsSet, pwmActiveHighLow, [this.wizardPages]);
       
    }

    findAttribute(attrId) {
        return this.attributes.find(attr => attr.id === attrId);
    }

    bindRule(rule, me, attributes) {
        rule = rule.bind(this, me, ...attributes)
        me.addRule(rule);
    }
}

export default Config500Obj
export { cctValues }
